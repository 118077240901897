import React from "react";
import PageView from "../../../components/views/table-view";
import { useClasses } from "../../../hooks/useClasses";
import { getActionOptions } from "./constant";
import { useNavigate } from "react-router-dom";

const Classes = () => {
  const { classes, classData, isLoading, onDeleteClass, permission } = useClasses();
  const navigate = useNavigate();
  return (
    <PageView
      canCreate={permission?.create}
      rowHasUpdate={permission?.update}
      rowHasDelete={permission?.delete}
      onDelete={onDeleteClass}
      isLoading={isLoading}
      action={getActionOptions({ permission, navigate })}
      rowHasAction={permission?.create}
      // rowHasAction={permission?.action && indexStatus === "all"}
      columns={[
        {
          Header: "id",
          accessor: "id",
        },
        {
          Header: "Class Name",
          accessor: "class_name",
        },
        {
          Header: "Sub Classes",
          accessor: "sub_class",
        },
      ]}
      data={classes}
    />
  );
};

export default Classes;
